import { Unstable_Popup as MuiPopup } from "@mui/base";
import type { PopupProps as MuiBasePopupProps } from "@mui/base/Unstable_Popup/index.js";
import type { MouseEvent, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type PopupProps = {
  /**
   * The element to render this next to
   */
  anchor?: MuiBasePopupProps["anchor"];
  /**
   * Add class names to the popup
   */
  className?: string;
  /**
   * Content to render inside the popup
   */
  children: ReactNode;
  /**
   * The id of the popup
   */
  id?: string;
  /**
   * Keydown event listener for the popup element
   */
  onKeyDown?: MuiBasePopupProps["onKeyDown"];
  /**
   * Whether the popup is visible or not
   * @default false
   */
  open?: boolean;
  /**
   * Where to render the popup relative to the anchor
   * @default bottom
   */
  placement?: MuiBasePopupProps["placement"];
  /**
   * Add inline styles to the popup
   */
  style?: MuiBasePopupProps["style"];
};

export default function Popup({
  children,
  placement = "bottom",
  anchor,
  open,
  className,
  onKeyDown,
  style,
  id,
}: PopupProps) {
  return (
    <MuiPopup
      id={id}
      placement={placement}
      anchor={anchor}
      open={open}
      className={twMerge("z-40", className)}
      onClick={(e: MouseEvent) => e.stopPropagation()}
      style={{
        ...(anchor && "offsetWidth" in anchor ? { minWidth: anchor.offsetWidth } : undefined),
        ...style,
      }}
      onKeyDown={onKeyDown}
      strategy="fixed"
    >
      {children}
    </MuiPopup>
  );
}
